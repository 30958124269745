<template>
  <div class="mr-3">
    <v-row>
      <v-col cols="4" class="">
        <switchGroup
          type="switchGroup"
          :fieldAttrInput="fieldAttrInput"
          :fieldAttributes="{
            name: 'Test Direction',
            fields: templateContent.fields.uplinkDownlinkSwitch.fields,
          }"
          :value="valueLine[field]"
          v-on="$listeners"
          field="uplinkDownlinkSwitch"
          :templateContent="templateContent"
          :valueLine="valueLine"
          :disabled="fieldAttrInput.disabled"
          id="test_direction_duration_ip"
        ></switchGroup>
      </v-col>
      <v-col cols="2" class="mt-6 ml-n16 mr-n3" style="max-width: 250px">
        <primaryTextfield
          type="number"
          :fieldAttrInput="{ class: ' number' }"
          label="Test Duration in Seconds"
          v-model="time"
          :templateContent="result"
          :isValid="isValid"
        ></primaryTextfield>
      </v-col>
      <!--   <v-col class="mt-6 ml-n2">
                <associatedOption
                        :fieldAttributes="{ name: 'IP Version', associatedOption: localCodes }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="ipVersion"
                        :templateContent="result"
                        :isValid="isValid"
                ></associatedOption>
            </v-col>-->
      <v-col class="mt-6">
        <fieldsRows
          :templateContent="templateContentRow"
          :valueLine="valueLine"
          :isValid="isValid"
          v-on="$listeners"
        ></fieldsRows>
      </v-col>
    </v-row>
  </div>
</template>
<script>
//import associatedOption from "@/commonComponents/associatedOption.vue";
import switchGroup from "@/commonComponents/switchGroup.vue";
import { getfieldAttrInput } from "@/js/helper.js";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  components: {
    //  associatedOption,
    switchGroup,
    primaryTextfield,
    fieldsRows,
  },

  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],

  data() {
    return { localCodes: ["ipv4", "ipv6"] };
  },
  computed: {
    templateContentRow() {
      let templateContentRow = {
        fields: {
          congestionHighTimeLine: {
            type: "line",
            fields: {
              lowDelayVar: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { class: "" },
                name: "Low delay variation threshold in ms",
              },
              upperDelayVar: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { class: "" },
                name: "Upper delay variation threshold in ms",
              },
              c: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { class: "" },
                name: "Congestion slow adjustment threshold",
              },
              h: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { class: "" },
                name: "High speed (row adjustment) delta",
              },
            },
          },
        },
        // stepType: this.stepType,
        // stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
        //enableAdd: false,
        // contentID: this.stepType + "_" + this.stepID,
      };
      return templateContentRow;
    },
    /*      evalValue: {
        get() {
          let evalValue = { };
          // console.log(this.localStoredStep);
          if (
            typeof this.valueLine!= "undefined"
          ) {
            evalValue = this.valueLine;
          }
          return evalValue;
        },
        set(newVal) {
           this.updateResponseKey("time", newVal);
        },
      },*/
    time: {
      get() {
        let time = "";
        if (
          typeof this.valueLine != "undefined" &&
          typeof this.valueLine.time != "undefined"
        ) {
          time = this.valueLine.time;
        }
        return time;
      },
      set(newVal) {
        this.$set(this.valueLine, "time", newVal);
        //this.updateResponseKey("time", this.evalValue);
      },
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    templateContent() {
      let templateContent = {
        fields: {
          uplinkDownlinkSwitch: {
            type: "switchGroup",
            name: "Test Direction",
            fields: {
              uplink: {
                name: "Uplink",
              },
              downlink: {
                name: "Downlink",
              },
            },
          },
        },
        contentID: this.result.contentID,
        // stepType: this.stepType,
        // stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        //enableAdd: false,
        // contentID: this.stepType + "_" + this.stepID,
      };
      return templateContent;
    },
    /*      ipVersion: {
        get() {
          //console.log(this.valueLine);
          let ipVersion = "";
          if (typeof this.valueLine.ipv4!= "undefined" && this.valueLine.ipv4==true) {
            ipVersion = "ipv4";
          }else if(typeof this.valueLine.ipv6!= "undefined" && this.valueLine.ipv6==true){
            ipVersion = "ipv6";
          }
          return ipVersion;
        },
        set(selected) {
          // console.log(selected);
          if(selected=="ipv4"){
            this.$set(this.valueLine, "ipv6", false);
          }else if(selected=="ipv6"){
            this.$set(this.valueLine, "ipv4", false);
          }
          this.$set(this.valueLine, selected, true);
        },
      },*/
  },
  methods: {
    /*      updateResponseKey(index, newVal) {
        //console.log( "---",index, newVal);
        this.$set(this.localStoredStep["parameters"], index,newVal  );
        this.$emit("stored-value-changed", this.localStoredStep);
      },*/
    /*     updateFieldValue(index, value) {
          console.log("--->",index,value);
        this.$set(this.valueLine, index, value);
        this.$emit("stored-value-changed", this.valueLine);
      },*/
  },
};
</script>